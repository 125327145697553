.abstract {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  max-width: 700px;
  margin: 100vh auto 0;
  padding: 0 20px;

  @media (min-width: 900px) {
    max-width: 900px;
  }

  p {
    @media (min-width: 900px) {
      padding: 160px 180px;
    }
  }
}