.header {
  position: fixed;
  min-width: 60px;
  padding: 15px;
  flex-shrink: 0;
  z-index: 100;
  top: 0;
  width: calc(100% - 30px);
  left: 0;
  height: calc(100vh - 30px);
  transition: all .33s;

  img {
    width: 100%;
    height: 100%;
  }

  &.blurry {
    opacity: .4;
    filter: blur(10px);
  }
}