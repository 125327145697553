.container {
  flex-basis: 100%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 100;

  p {
    background-color: white;
    padding: 25px;
  }
}