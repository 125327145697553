.photo {
  max-width: 80%;
  max-height: 100%;
  padding: 5%;
  object-fit: contain;

  &.placeholder {
    width: 100%;
    height: 100%;
  }
}

.photo__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
}

.photo--animate {
  animation: animate-photo 1s;
}

@keyframes animate-photo {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}